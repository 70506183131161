const slugify = require("slugify")

const makePointPath = (point) => {
    return "/punkt/" + slugify(point.title) + "-" + String(point.id)
}

const makeTrackPath = (track) => {
    return "/trasa/" + slugify(track.title) + "-" + String(track.id)
}

module.exports = {makePointPath, makeTrackPath}