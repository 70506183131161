import { useState, useEffect } from "react"
import Fuse from 'fuse.js'


const useTracks = (tracks, {activitiesFilters, levelsFilters, municipalitiesFilters, searchFilter="", subjectsFilters}) => {
    const [filteredTracks, setFilteredTracks] = useState(tracks)
    const options = {
        keys: ["title"]
    }
    const fuse = new Fuse(tracks, options)

    useEffect(() => {
        const tracksToFilter = searchFilter === "" ? tracks : fuse.search(searchFilter).map(r => r.item)
        const filteredTracks = tracksToFilter.filter(t => {
            if (levelsFilters.length === 0) {
                return true
            }
            for (let level of t.levels) {
                if (levelsFilters.indexOf(level) !== -1) {
                    return true;
                }
            }
            return false;
        }).filter(t => {
            if (activitiesFilters.length === 0) {
                return true
            }
            for (let activity of t.activities) {
                if (activitiesFilters.indexOf(activity) !== -1) {
                    return true;
                }
            }
            return false;
        }).filter(t => {
            if (subjectsFilters.length === 0) {
                return true
            }
            for (let subject of t.subjects) {
                if (subjectsFilters.indexOf(subject) !== -1) {
                    return true;
                }
            }
            return false;
        }).filter(t => {
            if (municipalitiesFilters.length === 0) {
                return true
            }
            if (municipalitiesFilters.indexOf(t.municipality) !== -1) {
                return true;
            }
            return false;
        })
        setFilteredTracks(filteredTracks)
    }, [activitiesFilters, levelsFilters, subjectsFilters, searchFilter, municipalitiesFilters])

    return filteredTracks
}

export default useTracks